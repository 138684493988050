import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileService } from './../_services/FileService';
import { authAtom } from './../_state/auth';
import { useRecoilValue } from 'recoil';
import { useUserActions } from './../_actions/user.actions';
import moment from 'moment';
import { ProgressBar } from 'primereact/progressbar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useQuery } from 'react-query';
import { getLanguages } from '../_services';


export const Download = () => {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [totalDownload, setTotalDownload] = useState(0);
    const [ytdDownload, setYTDDownload] = useState(0);
    const [mtdDownload, setMTDDownload] = useState(0);
    const [globalFilter, setGlobalFilter] = useState(null);
    const auth = useRecoilValue(authAtom);
    const [products, setProducts] = useState(null);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const userActions = useUserActions();
    const fileService = new FileService();
    const { data } = useQuery('languges', getLanguages);



    useEffect(() => {

        let str = "Italian_TeeVo_February_2022.pdf";

        var strPos = str.indexOf("_");
        console.log(strPos);

        let result = str.substring(0, strPos);
        console.log(result);


        var pos1 = str.indexOf("_");           // 3
        var pos2 = str.indexOf("_", pos1 + 1); // 7
        var pos3 = str.indexOf("_", pos2 + 2); // 7
        console.log(pos3 - pos2 - 1);

        let result1 = str.substring(0, pos3);
        console.log(result1);

        getFiles();
        getYTMCount();
        getYTDCount();


    }, []);


    const createThumb = async () => {


    };



    function getYTDCount() {
        setIsLoading(true);

        var dateNow = new Date();

        var startDate = dateNow.getFullYear() + "-01-01";
        var endDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("YYYY-MM-DD");


        console.log('-------==================-----------');
        console.log(auth?.id);
        console.log(startDate);
        console.log(endDate);
        console.log('-------======================----------');


        userActions.getDownloadCountByUserId(auth?.id, startDate, endDate)
            .then((res) => {
                setIsLoading(false);
                setYTDDownload(res);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });

    }


    function getYTMCount() {
        setIsLoading(true);

        var dateNow = new Date();

        var startDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("YYYY-MM-DD");
        var endDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("YYYY-MM-DD");


        userActions.getDownloadCountByUserId(auth?.id, startDate, endDate)
            .then((res) => {
                setIsLoading(false);
                setMTDDownload(res);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });

    }

    function getDownloadsByUserid() {
        setIsLoading(true);
        userActions.totalDownloadsByUserid(auth?.id)
            .then((res) => {
                setIsLoading(false);
                setTotalDownload(res);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });

    }


    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            {/* <h5 className="m-0">File Manager</h5> */}
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    function getFiles() {
        try {
            setIsLoading(true);
            fileService.getFiles().then(data => {
                setIsLoading(false);
                console.log(data);
                setProducts(data);

                createThumb();


                getDownloadsByUserid();
            });
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }
    const downloadFile = async (id, path, mimetype) => {
        try {
            setIsLoading(true);
            fileService.downloadFile(id, path, mimetype);
            fileService.downloadTransaction(auth.id, id);
            fileService.countDownload(auth.id, id);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            if (error.response && error.response.status === 400) {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while downloading file. Try again later', life: 3000 });
            }
        }
    };

    const onDownloadFile = (file) => {

        console.log('********************************************');
        console.log(file);
        console.log('********************************************');

        downloadFile(file._id, file.file_path, file.file_mime_type);
    }

    const onCountryChange = (e) => {
        console.log(e.value);
        setSelectedCountry(e.value);
    };


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-download" className="p-button-rounded p-button-success mr-2" onClick={() => onDownloadFile(rowData)} />
            </div>
        );
    }


    const onDownloadClick = () => {
        let bookName = selectedCountry;

        getByBookName(bookName.name[0]);

    };


    function getByBookName(name) {
        try {
            setIsLoading(true);
            fileService.getByBookName(name).then(data => {


                setIsLoading(false);

                onDownloadFile(data)

            });
        } catch (error) {
            setIsLoading(false);
            console.log(error);
        }
    }

    if (isLoading) return <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>

    return (

        <>

            <div className="card">
                <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                    <div>
                        <div className="font-medium text-3xl text-900">Welcome  {auth.lastName + ' ' + auth.firstName} </div>
                        <div className="flex align-items-center text-700 flex-wrap">
                            <div className="mr-5 flex align-items-center mt-3">
                                <i className="pi pi-users mr-2"></i>
                                <span>{auth?.email}</span>
                            </div>
                            <div className="mr-5 flex align-items-center mt-3">
                                <i className="pi pi-globe mr-2"></i>
                                <span>9402 Sessions</span>
                            </div>
                            <div className="flex align-items-center mt-3">
                                <i className="pi pi-clock mr-2"></i>
                                <span>2.32m Avg. Duration</span>
                            </div>
                        </div>
                    </div>


                    <h5>Select Language</h5>
                    <Dropdown value={selectedCountry} options={data} onChange={onCountryChange} optionLabel="name" placeholder="Select a Language" />

                    <div className="mt-3 lg:mt-0">
                        <Button label="Download" className="p-button-outlined mr-2" icon="pi pi-user-plus" onClick={onDownloadClick} />
                    </div>
                </div>
            </div>




            {!isLoading &&
                <div className="col-12">
                    <div className="card">
                        <Toast ref={toast} />

                        <DataTable ref={dt} value={products} selection={selectedProducts} onSelectionChange={(e) => setSelectedProducts(e.value)}
                            dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} files" header={header} globalFilter={globalFilter}
                            emptyMessage="No Book was found." responsiveLayout="scroll">
                            <Column header="Download" body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="book_name" header="Book Name" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="book_language" header="Language" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="book_month" header="Month" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                            <Column field="file_size" header="File Size" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                        </DataTable>


                    </div>
                </div>
            }
        </>

    );
}
