import React, { useState, useEffect, useRef } from 'react';
import { Messages } from 'primereact/messages';
import { Button } from 'primereact/button';
import { FileService } from '../_services/FileService';
import { authAtom } from '../_state/auth';
import { useRecoilValue } from 'recoil';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { useUserActions } from '../_actions/user.actions';
import { ProgressSpinner } from 'primereact/progressspinner';

export const DownloadPage = () => {

    const auth = useRecoilValue(authAtom);

    const toast = useRef(null);

    const fileService = new FileService();

    const navigate = useNavigate();

    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const msgError = useRef(null);
    const [showSpinner, setShowSpinner] = useState(false);
    const [yearOptions, setYearOptions] = useState([]);
    const userActions = useUserActions();
    const [selectedTeeVo, setSelectedTeeVo] = useState(null);
    const [teeVoList, setTeeVoList] = useState([]);

    const months = [
        { name: 'January', value: 'January' },
        { name: 'February', value: 'February' },
        { name: 'March', value: 'March' },
        { name: 'April', value: 'April' },
        { name: 'May', value: 'May' },
        { name: 'June', value: 'June' },
        { name: 'July', value: 'July' },
        { name: 'August', value: 'August' },
        { name: 'September', value: 'September' },
        { name: 'October', value: 'October' },
        { name: 'November', value: 'November' },
        { name: 'December', value: 'December' }
    ];

    useEffect(() => {
        if (auth?.role === 'Admin') {
            navigate('/admin/dashboard', { replace: true });
        }

        const currentYear = new Date().getFullYear();
        const years = Array.from({ length: currentYear - 2019 }, (_, index) => (currentYear - index).toString());
        setYearOptions(years.map(year => ({ name: year, value: year })));

        const year = new Date().getFullYear();
        const month = new Date().toLocaleString("en-US", { month: "long" });

        setSelectedMonth(month);
        setSelectedYear(year.toString());

        const loadTeevos = () => {
            const year = new Date().getFullYear();
            const month = new Date().toLocaleString("en-US", { month: "long" });

            fileService.getTeeVoLanguagesByBookName('TeeVo', month, year)
                .then((res) => {
                    setTeeVoList(res.data);
                    setShowSpinner(false);
                }).catch((error) => {
                    setShowSpinner(false);
                    msgError.current.show([{ severity: 'error', detail: error, sticky: false, life: 5000 }]);
                });
        }
        setShowSpinner(true);
        loadTeevos();
    }, []);


    const getLanguages = (year, month) => {
        fileService.getTeeVoLanguagesByBookName('TeeVo', month, year)
            .then((res) => {
                setTeeVoList(res.data);
                setShowSpinner(false);
            }).catch((error) => {
                setShowSpinner(false);
                msgError.current.show([{ severity: 'error', detail: error, sticky: false, life: 5000 }]);
            });
    };

    const onYearChange = (e) => {
        setSelectedYear(e.value);
        getLanguages(e.value, selectedMonth);
    };

    const onMonthChange = (e) => {
        setSelectedMonth(e.value);
        getLanguages(selectedYear, e.value);
    };


    const onTeeVoChange = (e) => {
        setSelectedTeeVo(e.value);
    };

    // const onYearChange = (e) => {
    //     setSelectedYear(e.value);
    // };


    // const onMonthChange = (e) => {
    //     setSelectedMonth(e.value);
    // };

    const onDownloadTeeVoClick = () => {
        if (!selectedYear || !selectedMonth || !selectedTeeVo) {
            setShowSpinner(false);
            msgError.current.show([{ severity: 'error', detail: 'Please select a year, month, and language', sticky: false, life: 5000 }]);
            return;
        }
        setShowSpinner(true);
        DownloadTeevo(selectedTeeVo.book_language, 'TeeVo', selectedMonth, selectedYear);
    };

    const handleSignOutClick = () => {
        userActions.logout();
        navigate("/en/dist");
    };

    const handleHomePageClick = () => {
        window.location.href = "https://www.teevotogo.org/";
        userActions.logout();
    };

    const downloadFile = async (id, path, mimetype) => {
        try {

            fileService.downloadFile(id, path, mimetype);
            fileService.downloadTransaction(auth.id, id);
            fileService.countDownload(auth.id, id);

            setShowSpinner(false);
        } catch (error) {
            setShowSpinner(false);
            if (error.response && error.response.status === 400) {
                setShowSpinner(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error while downloading file. Try again later', life: 3000 });
            }
        }
    };

    function DownloadTeevo(language, book, month, year) {
        fileService.DownloadTeevo(language, book, month, year).then(response => {
            if (response.data === null || response.data.length === 0) {
                setShowSpinner(false);
                msgError.current.show([{ severity: 'error', detail: 'The selected edition does not exist.', sticky: false, life: 5000 }]);
            } else {
                const _file = { ...response.data };
                const fileid = _file[0]._id;
                const filePath = _file[0].file_path;
                const fileMimeType = _file[0].file_mime_type;
                downloadFile(fileid, filePath, fileMimeType);
            }

        })
    }

    return (
        <>
            <Messages ref={msgError} />
            {showSpinner &&
                <div className="p-fluid">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                </div>
            }

            <div className="card">
                <div className="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
                    <div className="font-medium text-3xl text-900">Welcome {auth?.fullName}</div>
                    <div className="flex align-items-center text-700 flex-wrap">
                        <div className="mr-5 flex align-items-center mt-3">
                            <i className="pi pi-users mr-2"></i>
                            <span>If you wish to download previous editions, kindly select the year and month of the desired edition</span>
                        </div>
                    </div>
                </div>
                <div className="col-12 xl:col-12">
                    <div className="grid p-fluid">
                        <div className="col-8 md:col-4">
                            <br />
                            <br />
                        </div>
                        <div className="grid p-fluid" id="passMonth">
                            <div className="col-8 md:col-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={selectedYear} options={yearOptions} onChange={onYearChange} optionLabel="name" placeholder="Select Year" />
                                </div>
                            </div>
                            <div className="col-8 md:4">
                                <div className="p-inputgroup">
                                    <Dropdown value={selectedMonth} options={months} onChange={onMonthChange} optionLabel="name" placeholder="Select Month" />
                                </div>
                            </div>
                            <div className="col-8 md:col-4">
                                <div className="p-inputgroup">
                                    <Dropdown value={selectedTeeVo} options={teeVoList} onChange={onTeeVoChange} optionLabel="book_language" placeholder="Select Language" />
                                    <Button icon="pi pi-download" rounded aria-label="Filter" onClick={onDownloadTeeVoClick} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex align-items-start">
                <Button icon="pi pi-home" rounded text raised severity="warning" className="flex align-items-center" aria-label="Notification" onClick={handleHomePageClick} />
                <Button icon="pi pi-user" rounded text raised severity="warning" className="flex align-items-center ml-2" aria-label="User" onClick={handleSignOutClick} />
            </div>
        </>
    );
}

