import React, { useState, useEffect } from 'react';
//import { useMutation, useQuery, useQueryClient } from "react-query";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom } from '../_state/auth';
//import Spinner from './Profile';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { useUserActions } from './../_actions/user.actions';
import { ProgressBar } from 'primereact/progressbar';
import { DoughnutChart } from './charts/DoughnutChart';
//import { PieChart } from './charts/PieChart';
import { BarChart } from './charts/BarChart';
import { LineChart } from './charts/LineChart';

export const Dashboard = (props) => {
    const auth = useRecoilValue(authAtom);
    const [isLoading, setIsLoading] = useState(true);
    // const [totalDownload, setTotalDownload] = useState(0);
    // const [ytdDownload, setYTDDownload] = useState(0);
    // const [mtdDownload, setMTDDownload] = useState(0);
    const [books, setBooks] = useState([]);
    const [downloadCount, setDownloadCount] = useState(0);
    const userActions = useUserActions();
    const navigate = useNavigate();
    const [lineOptions, setLineOptions] = useState(null);

    // const { isLoading, error, data, isFetching } = useQuery("churches", searchDownloadCount);

    const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const [selectedBook, setSelectedBook] = useState();

    const bookTypes = [
        { name: 'Teevo' },
        { name: 'Take5' }
    ];





    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)',
                    }
                },
            }
        };

        setLineOptions(lineOptions)
    }

    if (!auth) {
        // navigate('/login');
        navigate('/en/dist');
    }


    ///en/dist
    if (auth?.role === 'User') {
        // navigate('/user/download');
        navigate('/en/dist');
    }


    useEffect(() => {
        if (props.colorMode === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }

        var dateNow = new Date();
        var startDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
        var endDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0);

        setStartDate(startDate);
        setEndDate(endDate);

        setSelectedBook(bookTypes[0]);

        // getYTMCount();
        // getYTDCount();
        // getTotalCount();
        // getAllDownloadByBookType();


        onSearchClick();

    }, [props.colorMode]);



    // function getAllDownloadByBookType() {
    //     setIsLoading(true);
    //     userActions.getAllDownloadByBookType()
    //         .then((res) => {
    //             setIsLoading(false);
    //             console.log(res);
    //             setBooks(res);
    //         })
    //         .catch(error => {
    //             setIsLoading(false);
    //             console.log(error);
    //         });

    // }

    // function getTotalCount() {
    //     setIsLoading(true);
    //     userActions.getAllDownloads()
    //         .then((res) => {
    //             setIsLoading(false);
    //             setTotalDownload(res);
    //         })
    //         .catch(error => {
    //             setIsLoading(false);
    //             console.log(error);
    //         });

    // }

    // function getYTDCount() {
    //     setIsLoading(true);

    //     var dateNow = new Date();

    //     var startDate = dateNow.getFullYear() + "-01-01";
    //     var endDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("YYYY-MM-DD");


    //     userActions.getDownloadsByDateRange(startDate, endDate)
    //         .then((res) => {
    //             setIsLoading(false);
    //             setYTDDownload(res);
    //         })
    //         .catch(error => {
    //             setIsLoading(false);
    //             console.log(error);
    //         });

    // }

    // function getYTMCount() {
    //     setIsLoading(true);

    //     var dateNow = new Date();

    //     var startDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth(), 1)).format("YYYY-MM-DD");
    //     var endDate = moment(new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0)).format("YYYY-MM-DD");



    //     console.log('-------hhhhhhhhhhhhhhhhhhhhhhhhhhh----------');
    //     console.log(startDate);
    //     console.log(endDate);
    //     console.log('-------hhhhhhhhhhhhhhhhhhhhhhhhhhhh----------');




    //     userActions.getDownloadsByDateRange(startDate, endDate)
    //         .then((res) => {
    //             setIsLoading(false);
    //             setMTDDownload(res);
    //         })
    //         .catch(error => {
    //             setIsLoading(false);
    //             console.log(error);
    //         });

    // }

    const data = {
        labels: books.map((data) => data._id),
        datasets: [
            {
                label: `${selectedBook?.name} : ${downloadCount}`,
                fill: false,
                borderColor: '#42A5F5',
                tension: .5,
                data: books.map((data) => data.count),
                backgroundColor: [
                    "#42A5F5",
                    "#66BB6A",
                    "#FFA726",
                    "#cbb06c",
                    "#f542a5",
                    "#f59242",
                    "#ac95de",
                    "#F4413E",
                    "#45a7e8",
                    "#1a4262"
                ],
                hoverBackgroundColor: [
                    "#64B5F6",
                    "#81C784",
                    "#FFB74D",
                    "#cbb06c",
                    "#f542a5",
                    "#f59242",
                    "#ac95de",
                    "#F4413E",
                    "#45a7e8",
                    "#1a4262"
                ],
                borderWidth: 1,
            },
        ],
    };

    const onBookChange = (e) => {
        console.log(e.value);
        setSelectedBook(e.value);

        var start = moment(new Date(startDate)).format("YYYY-MM-DD");
        var end = moment(new Date(endDate)).format("YYYY-MM-DD");

        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
        console.log(start);
        console.log(end);
        console.log(e.value?.name);

        console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
        setDownloadCount(0);
        onSearch(e.value?.name);


    };



    const onSearchClick = () => {
        let bookName = 'Teevo';

        setIsLoading(true);

        console.log('0000000000000000000000000000000000000');


        if (selectedBook !== undefined) {
            bookName = selectedBook.name;
        }


        console.log(bookName);

        console.log('0000000000000000000000000000000000000');
        onSearch(bookName);
    };

    const onSearch = (bookName) => {

        var start = moment(new Date(startDate)).format("YYYY-MM-DD");
        var end = moment(new Date(endDate)).format("YYYY-MM-DD");

        userActions.getcountDownloadByDateRange(bookName, start, end)
            .then((res) => {
                setIsLoading(false);
                setBooks(res);

                let downloadCount = 0;
                res.forEach((el) => {
                    console.log(el);
                    console.log(el.count);
                    downloadCount += el.count;
                });

                console.log(downloadCount);
                setDownloadCount(downloadCount);
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            });
    };

    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .74,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        let multiAxisOptions = {
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: 0,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y1: {
                    type: 'linear',
                    display: true,
                    position: 'right',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        drawOnChartArea: false,
                        color: '#ebedef'
                    }
                }
            }
        };

        return {
            basicOptions,
            multiAxisOptions
        }
    }
    const { basicOptions, multiAxisOptions } = getLightTheme();

    // const lightOptions = {
    //     plugins: {
    //         legend: {
    //             labels: {
    //                 color: '#495057'
    //             }
    //         }
    //     }
    // };

    if (isLoading) return <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>

    return (
        <div className="grid">

            {/* <div className="col-12 lg:col-6 xl:col-4">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Total Downloads</span>
                            <div className="text-900 font-medium text-xl">{totalDownload}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-4">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Total Year to Date</span>
                            <div className="text-900 font-medium text-xl">{ytdDownload}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-map-marker text-orange-500 text-xl" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-4">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Total Month to Date</span>
                            <div className="text-900 font-medium text-xl">{mtdDownload}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-inbox text-cyan-500 text-xl" />
                        </div>
                    </div>
                </div>
            </div>   */}

            <div className="col-12 xl:col-12">
                <div className="grid p-fluid">
                    <div className="col-12 md:col-4">
                        <div className="p-inputgroup">
                            <Button disabled label="Start Date" />
                            <Calendar id="icon" value={startDate} onChange={(e) => setStartDate(e.value)} showIcon />
                        </div>
                    </div>
                    <div className="col-12 md:col-4">
                        <div className="p-inputgroup">
                            <Button disabled label="End Date" />
                            <Calendar id="icon" value={endDate} onChange={(e) => setEndDate(e.value)} showIcon />
                        </div>

                    </div>
                    <div className="col-12 md:col-4">
                        <div className="p-inputgroup">
                            <Dropdown value={selectedBook} options={bookTypes} onChange={onBookChange} optionLabel="name" placeholder="Select a Book" />
                            <Button icon="pi pi-search" className="p-button-warning" onClick={onSearchClick} />
                        </div>

                    </div>
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h2> {selectedBook?.name} : {downloadCount} </h2>
                    <DataTable value={books}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} translations"
                        emptyMessage="No language found." responsiveLayout="scroll">
                        <Column field="_id" header="Language" sortable headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                        <Column field="count" header="Count" sortable headerStyle={{ width: '7%', minWidth: '7rem' }}></Column>
                    </DataTable>
                </div>
                <div className="card">
                    <LineChart chartData={data} options={basicOptions} />
                </div>
                {/* <div className="card">
                    <PieChart chartData={data} options={basicOptions} />
                </div> */}
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <BarChart chartData={data} options={basicOptions} />
                </div>
                <div className="card">
                    <DoughnutChart chartData={data} options={basicOptions} />
                </div>
            </div>
        </div>

    );
}
