
import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { countries } from './../../_data/Countries';
import { register } from './../../_services';
import { useMutation } from "react-query";
import { Toolbar } from 'primereact/toolbar';


function Profile() {
    const msgError = useRef(null);
    const [setFormData] = useState({});
    const [checked] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [products, setProducts] = useState(null);


    let emptyUser = {
        title: 'Mr',
        lastName: '',
        firstName: '',
        email: '',
        password: '',
        confirmPassword: '',
        church: '',
        country: '',
        address: '',
        isMember: true,
        acceptTerms: false
    };

    const [user, setUser] = useState(emptyUser);

    const titles = [
        { name: 'Pastor' },
        { name: 'Mr' },
        { name: 'Mrs' },
        { name: 'Miss' },
        { name: 'Sister' },
        { name: 'Brother' }
    ];

    const roles = [
        { name: 'Super Admin' },
        { name: 'Admin' },
        { name: 'User' }
    ];


    const saveUser = () => {
        setSubmitted(true);

        console.log(user);

        if (user.firstName.trim()) {
            let _products = [...products];
            let _product = { ...user };
            if (user.id) {
                const index = findIndexById(user.id);

                _products[index] = _product;
                //  toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000 });
            }
            else {

                _product.image = 'user-placeholder.svg';
                _products.push(_product);
                //   toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000 });
            }

            setProducts(_products);
            // setProductDialog(false);
            setUser(emptyUser);
        }
    }


    const deleteUser = () => {
        let _products = products.filter(val => val.id !== user.id);
        setProducts(_products);
        //  setDeleteProductDialog(false);
        setUser(emptyUser);
        //  toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    }


    // const onChurchChange = (e) => {
    //     let _product = { ...user };
    //     _product['church'] = e.value;
    //     setUser(_product);
    // }

    const onCountryChange = (e) => {
        let _product = { ...user };
        _product['country'] = e.value;
        setUser(_product);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...user };
        _product[`${name}`] = val;

        setUser(_product);
    }



    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }
    const formik = useFormik({
        initialValues: {
            title: 'Mr',
            lastName: '',
            firstName: '',
            email: '',
            password: '',
            confirmPassword: '',
            church: '',
            country: '',
            address: '',
            isMember: true,
            acceptTerms: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.lastName) {
                errors.lastName = 'Last Name is required.';
            }

            if (!data.firstName) {
                errors.firstName = 'First Name is required.';
            }

            if (!data.address) {
                errors.address = 'Address is required.';
            }

            if (checked) {
                if (!data.church) {
                    errors.church = 'Church is required.';
                }
            }

            if (!data.email) {
                errors.email = 'Email is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            if (!data.confirmPassword) {
                errors.password = 'Confirm Password is required.';
            }

            if (data.password !== data.confirmPassword) {
                errors.confirmPassword = 'Passwords must match.';
            }

            if (!data.acceptTerms) {
                errors.acceptTerms = 'You need to agree to the terms and conditions.';
            }

            return errors;
        },
        onSubmit: (data) => {

            console.log(data.title.name);
            console.log(data.country._id);
            console.log(data.church.name);


            let _data = { ...data };
            _data['title'] = data.title.name;
            _data['country'] = data.country.country;
            _data['church'] = data.church.name;

            //setFormData(_data);
            console.log(_data);

            // createUser(_data);
        }
    });

    const { isLoading: loading } = useMutation(register, {
        onError: (res) => {


            console.log(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        }, onSuccess: (data) => {
            alert();
            setFormData(data);

        },
    });



    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);



    const rightToolbarTemplate = () => {

        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Save" icon="pi pi-plus" className="p-button-success mr-2" loading={loading} onClick={saveUser} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" loading={loading} onClick={deleteUser} />
                </div>
            </React.Fragment>

        )
    }


    return (

        <div className="layout-main">

            <div className="grid">
                <div className="col-12 md:col-12">
                    <div className="card p-fluid">
                        <Messages ref={msgError} />

                        <h5>User Profile</h5>

                        <div className="field grid">
                            <label htmlFor="Title" className="col-12 mb-2 md:col-2 md:mb-0">Title</label>
                            <div className="col-8 md:col-4">
                                <Dropdown id="title" name="title" value={user.title} onChange={(e) => onInputChange(e, 'title')} options={titles} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                                {submitted && !user.title && <small className="p-invalid">Title is required.</small>}
                            </div>

                        </div>

                        <div className="field grid">
                            <label htmlFor="firstName" className="col-12 mb-2 md:col-2 md:mb-0">First Name</label>
                            <div className="col-12 md:col-10">
                                <InputText id="firstName" value={user.firstName} onChange={(e) => onInputChange(e, 'firstName')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.firstName })} />
                                {submitted && !user.firstName && <small className="p-invalid">First Name is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="lastName" className="col-12 mb-2 md:col-2 md:mb-0">Last Name</label>
                            <div className="col-12 md:col-10">
                                <InputText id="lastName" value={user.lastName} onChange={(e) => onInputChange(e, 'lastName')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.lastName })} />
                                {submitted && !user.lastName && <small className="p-invalid">Last Name is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="email" className="col-12 mb-2 md:col-2 md:mb-0">Email</label>
                            <div className="col-12 md:col-10">
                                <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
                                {submitted && !user.email && <small className="p-invalid">Email is required.</small>}
                            </div>

                        </div>



                        <div className="field grid">
                            <label htmlFor="password" className="col-12 mb-2 md:col-2 md:mb-0">Password</label>
                            <div className="col-12 md:col-10">
                                <Password id="password" value={user.password} onChange={(e) => onInputChange(e, 'password')} toggleMask header={passwordHeader} footer={passwordFooter}
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !user.password })} />
                                {submitted && !user.password && <small className="p-invalid">Password is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="confirmPassword" className="col-12 mb-2 md:col-2 md:mb-0">Confirm Password</label>
                            <div className="col-12 md:col-10">
                                <Password id="confirmPassword" value={user.confirmPassword} onChange={(e) => onInputChange(e, 'confirmPassword')} toggleMask header={passwordHeader} footer={passwordFooter}
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !user.confirmPassword })} />
                                {submitted && !user.confirmPassword && <small className="p-invalid">Confirmed Password is required.</small>}
                            </div>
                        </div>

                        <div className="field grid">
                            <label htmlFor="address" className="col-12 mb-2 md:col-2 md:mb-0">Address</label>
                            <div className="col-12 md:col-10">
                                <InputText id="address" value={user.address} onChange={(e) => onInputChange(e, 'address')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.address })} />
                                {submitted && !user.address && <small className="p-invalid">First Name is required.</small>}
                            </div>
                        </div>


                        <div className="field grid">
                            <label htmlFor="address" className="col-12 mb-2 md:col-2 md:mb-0">Country</label>
                            <div className="col-12 md:col-10">
                                <Dropdown id="country" name="country" value={user.country} onChange={(e) => onCountryChange(e, 'country')} options={countries} optionLabel="country" />
                                {submitted && !user.country && <small className="p-invalid">Country is required.</small>}
                            </div>
                        </div>

                        {/* <div className="field grid">
                            <label htmlFor="isMember" className="col-12 mb-2 md:col-2 md:mb-0">Church</label>
                            <div className="col-12 md:col-10">
                                <Dropdown id="church" name="church" value={user.church} onChange={(e) => onChurchChange(e, 'church')} options={churches} optionLabel="church" />
                                {submitted && !user.church && <small className="p-invalid">Church is required.</small>}
                            </div>
                        </div> */}

                        <div className="field grid">
                            <label htmlFor="Title" className="col-12 mb-2 md:col-2 md:mb-0">Roles</label>
                            <div className="col-8 md:col-4">
                                <Dropdown id="role" name="role" value={user.role} onChange={(e) => onInputChange(e, 'role')} options={roles} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                                {submitted && !user.role && <small className="p-invalid">Role is required.</small>}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>

        </div>
    );
}

export { Profile };