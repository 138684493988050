import React, { useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { Messages } from 'primereact/messages';
import { useUserActions } from '../_actions/user.actions';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { authAtom } from './../_state/auth';
import { useRecoilValue } from 'recoil';

export const Signin = () => {
    const userActions = useUserActions();
    const msgError = useRef(null);
    const [checked, setChecked] = useState(false);
    const auth = useRecoilValue(authAtom);

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validate: (data) => {
            let errors = {};


            if (!data.email) {
                errors.email = 'Email is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }



            return errors;
        },
        onSubmit: (data) => {
            //  setFormData(data);
            setLoading(true);
            return userActions.login(data.email, data.password)
                .then(() => {
                    setLoading(false);
                    formik.resetForm();

                    //  console.log("++++++++++++++++++++++++++++")
                    //   console.log(auth);
                    //   console.log("++++++++++++++++++++++++++++")

                    ///en/dist
                    if (auth?.role === 'Admin') {
                        navigate(from, { replace: true });
                    }
                    navigate('/en/dist');
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                    msgError.current.show([{ severity: 'error', detail: err, sticky: false, life: 5000 }]);
                });
        }
    });



    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    return (
        <>
            <div>
                <Messages ref={msgError} />
                <h5 className="text-center">Sign In</h5>
                <form onSubmit={formik.handleSubmit} className="p-fluid">

                    <div className="field">
                        <span className="p-float-label p-input-icon-right">
                            <i className="pi pi-envelope" />
                            <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                            <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email*</label>
                        </span>
                        {getFormErrorMessage('email')}
                    </div>
                    <div className="field">
                        <span className="p-float-label">
                            <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('password') })} />
                            <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password*</label>
                        </span>
                        {getFormErrorMessage('password')}

                    </div>

                    <div className="flex align-items-center justify-content-between mb-6">
                        <div className="flex align-items-center">
                            <Checkbox id="rememberme" onChange={e => setChecked(e.checked)} checked={checked} binary className="mr-2" />
                            <label htmlFor="rememberme">Remember me</label>
                        </div>
                        <a className="ml-2 text-blue-500 text-right cursor-pointer" to="/forgotpassword">
                            <Link to="/account/forgot-password">Forgot your password?</Link> </a>
                    </div>


                    <div className="button-container">
                        <Button label="Login" icon="pi pi-check" loading={loading} className="mt-2" />
                        <span>Don’t have an account?<Link to="/Signup" className="p-link" >Sign-up here</Link></span>
                    </div>
                </form>
            </div>

        </>
    )

}
