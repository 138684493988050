import { Image } from 'primereact/image';
import { useRecoilValue } from 'recoil';
import React, { useRef, useState, useEffect } from 'react';
import { useUserActions } from './../_actions/user.actions';
import { useFormik } from 'formik';
import { authAtom } from './../_state/auth';
import { Signin } from './Signin';
import { useNavigate, useLocation } from 'react-router-dom';
import { DownloadPage } from './DownloadPage';
import './LandingPage.css';


export const LandingPage = () => {
  const userActions = useUserActions();
  const msgError = useRef(null);
  const [setAuthenticated] = useState(false);
  const auth = useRecoilValue(authAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validate: (data) => {
      let errors = {};


      if (!data.email) {
        errors.email = 'Email is required.';
      }
      else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        errors.email = 'Invalid email address. E.g. example@email.com';
      }

      if (!data.password) {
        errors.password = 'Password is required.';
      }



      return errors;
    },
    onSubmit: (data) => {
      //  setFormData(data);
      setLoading(true);
      return userActions.login(data.email, data.password)
        .then(() => {
          setLoading(false);
          formik.resetForm();
          navigate(from, { replace: true });
        })
        .catch(err => {
          setLoading(false);
          console.log(err);
          msgError.current.show([{ severity: 'error', detail: err, sticky: false, life: 5000 }]);
        });
    }
  });


  useEffect(() => {


    if (auth?.role === 'Admin') {
      setAuthenticated(true);
    }


  }, [auth?.role, setAuthenticated]);

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };


  const handleSignOutClick = () => {
    userActions.logout();
    navigate("/en/dist");

  };

  return (
    <>
      <header id="header" className="navbar navbar-expand-lg navbar-light navbar-end bg-white">
        <div className="container">
          <nav className="js-mega-menu navbar-nav-wrap hs-menu-initialized hs-menu-horizontal">
            <a className="navbar-brand" aria-label="Space">
              <Image className="avatar avatar-lg avatar-4x3" src="https://www.teevotogo.org/wp-content/uploads/2021/12/Teevotogo-logo-170x56-1.png" alt="Image" />
            </a>

            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">

                {auth ?
                  <li className="nav-item">
                    <a className="d-lg-inline-block btn btn-primary" onClick={handleSignOutClick}>Sign Out </a>
                  </li>
                  :
                  <li className="nav-item"></li>
                }

              </ul>
            </div>
          </nav>
        </div>
      </header>

      <div className="bg-primary-dark position-relative overflow-hidden">

        <div className="container content-space-2">
          <div className="row justify-content-center align-items-lg-center">
            <div className="col-md-8 col-lg-6 mb-7 mb-lg-0">

              <div className="ps-lg-8">
                <div className="card card-md">
                  <div className="card-body">
                    <div className="row align-items-sm-center mb-5">
                      <Image src="/images/reachout-world.jpg" alt="Image" />
                    </div>
                  </div>
                </div>

              </div>

            </div>

            <div className="col-md-8 col-lg-6">
              <div className="ps-lg-5">

                <div className="card card-lg">
                  <div className="card-body">
                    <div className="row align-items-sm-center mb-5">
                    </div>
                    {auth ? <DownloadPage /> : <Signin />}

                  </div>
                </div>

              </div>
            </div>

          </div>


        </div>

      </div>


      <footer className="bg-primary-dark border-top border-white-10">
        <div className="container">

          {/* End Row */}
          <div className="border-top border-white-10" />
          <div className="row align-items-md-end py-5">
            <div className="col-md mb-3 mb-md-0">
              <p className="text-white mb-0">© Copyright. {getCurrentYear()}  LoveWorld Teens Publishing. All rights reserved.</p>
            </div>

          </div>
        </div>
      </footer>


    </>
  )

}