import React, { useEffect, useState, useRef } from 'react';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Messages } from 'primereact/messages';
import { Password } from 'primereact/password';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { classNames } from 'primereact/utils';
import { Link, useNavigate } from 'react-router-dom';
import { countries } from '../_data/Countries';
import { useUserActions } from '../_actions/user.actions';
import { register } from '../_services';
import { useMutation } from "react-query";



export const Signup = () => {
    const msgError = useRef(null);
    const userActions = useUserActions();

    const [churches, setChurches] = useState([]);
    // const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const [showMessage, setShowMessage] = useState(false);
    const [formData, setFormData] = useState({});


    const [isMember, setIsMember] = useState(true);


    const [checked, setChecked] = useState(true);

    const { mutateAsync: registerUser, isLoading: loading } = useMutation(register, {
        onError: (res) => {
            setShowMessage(false);
            console.log(res.response.data.message);
            msgError.current.show([{ severity: 'error', detail: res.response.data.message, sticky: false }]);
        }, onSuccess: (data) => {
            setFormData(data);
            setShowMessage(true);
        },
    });

    const loadChurches = (churchId) => {
        userActions.getChurches()
            .then((res) => {
                setChurches(res);
                console.log(churches);
            }).catch((error) => {
                msgError.current.show([{ severity: 'error', detail: error, sticky: false, life: 5000 }]);
            });
    };


    useEffect(() => {
        loadChurches();

    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    const formik = useFormik({
        initialValues: {
            // title: 'Mr',
            lastName: '',
            firstName: '',
            email: '',
            password: '',
            confirmPassword: '',
            church: '',
            country: '',
            //   address: '',
            isMember: true,
            acceptTerms: false
        },
        validate: (data) => {
            let errors = {};

            if (!data.lastName) {
                errors.lastName = 'Last Name is required.';
            }

            if (!data.firstName) {
                errors.firstName = 'First Name is required.';
            }

            // if (!data.address) {
            //     errors.address = 'Address is required.';
            // }

            if (checked) {
                if (!data.church) {
                    errors.church = 'Church is required.';
                }
            }

            if (!data.email) {
                errors.email = 'Email is required.';
            }
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                errors.email = 'Invalid email address. E.g. example@email.com';
            }

            if (!data.password) {
                errors.password = 'Password is required.';
            }

            if (!data.confirmPassword) {
                errors.password = 'Confirm Password is required.';
            }

            if (data.password !== data.confirmPassword) {
                errors.confirmPassword = 'Passwords must match.';
            }

            if (!data.acceptTerms) {
                errors.acceptTerms = 'You need to agree to the terms and conditions.';
            }

            return errors;
        },
        onSubmit: (data) => {
            console.log(data.title.name);
            console.log(data.country._id);
            console.log(data.church.name);


            let _data = { ...data };
            _data['title'] = data.title.name;
            _data['country'] = data.country.country;
            _data['church'] = data.church.name;

            //setFormData(_data);
            console.log(_data);

            createUser(_data);
        }
    });


    function onDialogClose() {
        setShowMessage(false);
        formik.resetForm();
        navigate("/login");
    }

    const createUser = async (data) => {
        console.log(data);
        setFormData(data);
        await registerUser(data);
    }




    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={onDialogClose} /></div>;


    const passwordHeader = <h6>Pick a password</h6>;
    const passwordFooter = (
        <React.Fragment>
            <Divider />
            <p className="mt-2">Suggestions</p>
            <ul className="pl-2 ml-2 mt-0" style={{ lineHeight: '1.5' }}>
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
            </ul>
        </React.Fragment>
    );

    const onCheckChanged = (e) => {
        setChecked(e.checked)
        console.log(e.target)

        if (e.checked) {
            setIsMember(true);
        } else {
            setIsMember(false);
        }

    }

    return (
        <div className="bg-primary-dark position-relative overflow-hidden">

            <div className="container content-space-2">
                <div className="row justify-content-center align-items-lg-center">
                    <div className="col-md-8 col-lg-6 mb-7 mb-lg-0">
                        {/* Heading */}
                        <div className="pe-lg-3 mb-7">
                            <h1 className="display-3 text-white mb-3 mb-md-5">Reachout world
                                <span className="text-warning">
                                    <span className="js-typedjs"> with teevolution!</span>
                                </span>
                            </h1>
                            <p className="lead text-white-70"> Please sign up to start the downloadiang.</p>
                        </div>

                    </div>
                    {/* End Col */}
                    <div className="col-md-8 col-lg-6">
                        <div className="ps-lg-5">
                            {/* Card */}
                            <div className="card card-lg">
                                <div className="card-body">
                                    <div className="row align-items-sm-center mb-5">
                                    </div>

                                    <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
                                        <div className="flex align-items-center flex-column pt-6 px-3">
                                            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
                                            <h5>Registration Successful!</h5>
                                            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                                                Your account is registered under name <b>{formData.lastName} {formData.firstName}</b>,
                                                Please check <b>{formData.email} </b> for verification instructions
                                            </p>
                                        </div>
                                    </Dialog>

                                    <div>
                                        <h5 className="text-center">Register</h5>
                                        <form onSubmit={formik.handleSubmit} className="p-fluid">
                                            <Messages ref={msgError} />

                                            {/* <div className="field">
                                            <span className="p-float-label">
                                                <Dropdown id="title" name="title" value={formik.values.title} onChange={formik.handleChange} options={titles} optionLabel="name" className={classNames({ 'p-invalid': isFormFieldValid('title') })} />
                                                <label htmlFor="title">Title</label>
                                            </span>
                                            {getFormErrorMessage('title')}
                                        </div> */}

                                            <div className="field">
                                                <span className="p-float-label">
                                                    <InputText id="firstName" name="firstName" value={formik.values.firstName} onChange={formik.handleChange} autoFocus className={classNames({ 'p-invalid': isFormFieldValid('firstName') })} />
                                                    <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') })}>First Name*</label>
                                                </span>
                                                {getFormErrorMessage('firstName')}
                                            </div>
                                            <div className="field">
                                                <span className="p-float-label">
                                                    <InputText id="lastName" name="lastName" value={formik.values.lastName} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('lastName') })} />
                                                    <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') })}>Last Name*</label>
                                                </span>
                                                {getFormErrorMessage('lastName')}
                                            </div>
                                            <div className="field">
                                                <span className="p-float-label p-input-icon-right">
                                                    <i className="pi pi-envelope" />
                                                    <InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('email') })} />
                                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') })}>Email*</label>
                                                </span>
                                                {getFormErrorMessage('email')}
                                            </div>
                                            <div className="field">
                                                <span className="p-float-label">
                                                    <Password id="password" name="password" value={formik.values.password} onChange={formik.handleChange} toggleMask
                                                        className={classNames({ 'p-invalid': isFormFieldValid('password') })} header={passwordHeader} footer={passwordFooter} />
                                                    <label htmlFor="password" className={classNames({ 'p-error': isFormFieldValid('password') })}>Password*</label>
                                                </span>
                                                {getFormErrorMessage('password')}
                                            </div>
                                            <div className="field">
                                                <span className="p-float-label">
                                                    <Password id="confirmPassword" name="confirmPassword" value={formik.values.confirmPassword} onChange={formik.handleChange} toggleMask
                                                        className={classNames({ 'p-invalid': isFormFieldValid('confirmPassword') })} header={passwordHeader} footer={passwordFooter} />
                                                    <label htmlFor="confirmPassword" className={classNames({ 'p-error': isFormFieldValid('confirmPassword') })}>Confirm Password*</label>
                                                </span>
                                                {getFormErrorMessage('confirmPassword')}
                                            </div>

                                            {/* <div className="field">
                                            <span className="p-float-label p-input-icon-right">
                                                <i className="pi pi-address" />
                                                <InputText id="address" name="address" value={formik.values.address} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('address') })} />
                                                <label htmlFor="address" className={classNames({ 'p-error': isFormFieldValid('address') })}>Address*</label>
                                            </span>
                                            {getFormErrorMessage('address')}
                                        </div> */}

                                            <div className="field">

                                                <span className="p-float-label">
                                                    <Dropdown id="country" name="country" value={formik.values.country} onChange={formik.handleChange} options={countries} optionLabel="country" />
                                                    <label htmlFor="country">Country</label>
                                                </span>
                                            </div>

                                            <div className="field-checkbox">
                                                <Checkbox inputId="binary" checked={checked} onChange={onCheckChanged} />
                                                <label htmlFor="isMember">Are you a Christ Embassy Member?</label>
                                            </div>

                                            {isMember && <div className="field">
                                                <span className="p-float-label">
                                                    <Dropdown id="church" name="church" value={formik.values.church} onChange={formik.handleChange} options={churches} optionLabel="name" />
                                                    <label htmlFor="church">Church</label>
                                                </span>
                                                {getFormErrorMessage('church')}
                                            </div>}


                                            <div className="field-checkbox">
                                                <Checkbox inputId="acceptTerms" name="acceptTerms" checked={formik.values.acceptTerms} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('acceptTerms') })} />
                                                <label htmlFor="acceptTerms" className={classNames({ 'p-error': isFormFieldValid('acceptTerms') })}>I agree to the terms and conditions*</label>
                                            </div>


                                            <div className="button-container">
                                                <Button label="Submit" icon="pi pi-check" loading={loading} className="mt-2" />
                                                <br />
                                                <span>Have an account?<Link to="/login" className="p-link"> Login</Link></span>
                                            </div>


                                        </form>
                                    </div>

                                </div>
                            </div>
                            {/* End Card */}
                        </div>
                    </div>
                    {/* End Col */}
                </div>

            </div>

        </div>

    );
}
