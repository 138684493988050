import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Menu } from 'primereact/menu';
import { authAtom } from './../../_state/auth';
import { useRecoilValue } from 'recoil';
import { useUserActions } from './../../_actions/user.actions';
import './../../App.css';


export const AppTopbar = (props) => {
    const userActions = useUserActions();
    const menu = useRef(null);
    const navigate = useNavigate();
    const auth = useRecoilValue(authAtom);

    useEffect(() => {
        // const subscription = userActions.user.subscribe(x => setUser(x));
        //  return subscription.unsubscribe;
        //  op.current.hide();
        console.log(auth);
        console.log(auth?.role);

    }, [auth]);

    // console.log(user);
    // if (!user) return null;

    const items = [
        {

            items: [
                {
                    label: 'Profile',
                    icon: 'pi pi-refresh',
                    command: () => {
                        //  toast.current.show({ severity: 'success', summary: 'Updated', detail: 'Data Updated', life: 3000 });
                    }
                },
                {
                    label: 'Settings',
                    icon: 'pi pi-times',
                    command: () => {
                        //  toast.current.show({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                    }
                },
                {
                    label: 'Sign Out',
                    icon: 'pi pi-login',
                    command: () => {
                        userActions.logout();
                        navigate("/en/dist");
                    }
                }
            ]
        }
    ];

    return (
        <div className="layout-topbar">


            <Link to="/" className="layout-topbar-logo">
                <img src={props.layoutColorMode === 'light' ? 'assets/layout/images/logo-dark.svg' : 'assets/layout/images/logo-white.svg'} alt="logo" />
                <span>Teevo2go</span>
            </Link>

            <button type="button" className="p-link  layout-menu-button layout-topbar-button" onClick={props.onToggleMenuClick}>
                <i className="pi pi-bars" />
            </button>

            <button type="button" className="p-link layout-topbar-menu-button layout-topbar-button" onClick={props.onMobileTopbarMenuClick}>
                <i className="pi pi-ellipsis-v" />
            </button>

            <ul className={classNames("layout-topbar-menu lg:flex origin-top", { 'layout-topbar-menu-mobile-active': props.mobileTopbarMenuActive })}>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-calendar" />
                        <span>Events</span>
                    </button>
                </li>
                <li>
                    <button className="p-link layout-topbar-button" onClick={props.onMobileSubTopbarMenuClick}>
                        <i className="pi pi-cog" />
                        <span>Settings</span>
                    </button>
                </li>

                <li>
                    <Menu model={items} popup ref={menu} id="popup_menu" />
                    <button className="p-link user" onClick={(event) => menu.current.toggle(event)} >
                        <span className="avatar-image">
                            <i className="pi pi-user" />
                        </span>
                        <span className="user__info">
                            {(auth?.lastName && auth?.firstName) ? <span className="user__title">{auth.lastName + ' ' + auth.firstName} </span> : <span>Profile</span>}

                            {(auth?.email) ? <span className="user__title">{auth.email}</span> : <span>Username</span>}
                        </span>
                    </button>
                </li>
            </ul>
        </div>
    );
}
